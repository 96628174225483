import React from "react"
import Layout from "../components/Layout/Layout"
import { Link } from "gatsby"
import Container from "layout/Container"
import SEO from "layout/SEO"
import Collapsible from "elements/Collapsible"

import ContactUs from "../components/StaticPages/HelpCenter/ContactUs"

export default () => {
  return (
    <Layout>
      <Container isCentered>
        <SEO title="Help Center" />
        <h2> Frequently Asked Questions</h2>
        <hr />
        <h4>Order</h4>
        <Collapsible title="How do I order?">
          To order medicines: <br />
          <ol>
            <li>Click “Order Medicines” on medgrocer.com</li>
            <li>Upload a photo of your prescription</li>
            <li>
              Search for the medicines and indicate the quantity you want to
              order
            </li>
            <li>
              Add other attachments, IDs, and coupon codes (if applicable)
            </li>
            <li>Provide your personal and delivery details</li>
            <li>Receive an email summarizing your order </li>
            <li>
              Wait for the confirmation message from our team to verify your
              order.
            </li>
            <li>Confirm your order</li>
          </ol>
        </Collapsible>
        <Collapsible title="Can I order in bulk for my company?">
          Definitely. Email us at{" "}
          <a
            href="mailto:order@medgrocer.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            order@medgrocer.com{" "}
          </a>{" "}
          for inquiries.
        </Collapsible>
        <Collapsible title="How do I pay for my order?">
          You can pay via bank transfer/bank deposit.
        </Collapsible>
        <Collapsible title="How would I know if my order went through?">
          You will receive a confirmation email immediately after ordering. Our
          pharmacist will reach out to you to verify your order. If you do not
          receive a confirmation message from MedGrocer within two working days,
          you may contact us at order@medgrocer.com.
        </Collapsible>
        <Collapsible title="How long does it take to process my order?">
          Orders will be processed on Monday to Friday, 8AM to 4PM. Orders made
          during that time are processed within two working days.
          <br />
          <br />
          To prevent miscommunication and minimize inaccuracies, requests are
          considered final upon submission. For any revisions, you may send an
          email to order@medgrocer.com requesting to cancel your order and
          submit a new request through medgrocer.com.
        </Collapsible>

        <Collapsible title="How do I cancel my order?">
          You may only cancel your order if it has not yet been shipped. Contact
          us at order@medgrocer.com to confirm the status of your order. If your
          order can be cancelled, you will receive an email that your order has
          been cancelled.
        </Collapsible>

        <Collapsible title="Can I order for someone else?">
          Definitely. If you want your medicines to be delivered straight to the
          person you’re ordering for, just input their delivery address.
        </Collapsible>
        <Collapsible title="Is there a minimum order?">
          There's no minimum order for delivery.
        </Collapsible>
        <h4>Prescription</h4>
        <Collapsible title="Do I need a prescription to buy medicines?">
          Yes. Just like any pharmacy, we require a valid prescription that
          contains the following details: prescription date, patient’s name,
          prescriber’s name, and prescriber’s license number.
          <br />
          <br />
          Over-the-counter medicines can be ordered without a prescription.
        </Collapsible>
        <Collapsible title="How do I send my prescription?">
          You can upload a picture or a scanned copy of your prescription when
          you request your medicines. You can also present the original copy of
          the prescription to our courier upon delivery of your order.
        </Collapsible>

        <h4>Delivery</h4>
        <Collapsible title="What are the areas covered for delivery? Is there a convenience fee?">
          We currently offer nationwide delivery. Php 200 convenience fee
          applies to NCR deliveries and Php 500 delivery fee applies to non-NCR
          deliveries.
          <br />
          <br />
          The convenience fee covers the cost of packaging, pharmacist advice,
          and delivery.
        </Collapsible>
        <Collapsible title="Can I pick-up the medicines in the MedGrocer office?">
          Our operations are streamlined to give you a hassle-free delivery
          experience. Please use our delivery service for your convenience.
        </Collapsible>
        <Collapsible title="Can you have it delivered to my workplace?">
          We deliver to home and office addresses. Just indicate the complete
          delivery address when you order.
        </Collapsible>
        <Collapsible title="What if I'm not at home when the order arrives?">
          Your representative may present a copy of your ID, prescription (if
          applicable), other necessary documents (i.e. senior citizen or PWD
          ID), and payment to receive your order.
        </Collapsible>
        <h4>Discount and Promos</h4>
        <Collapsible title="Do you honor Senior Citizen and PWD discounts?">
          Yes. If you are registered as a Senior Citizen or a Person With
          Disability (PWD) with the government, you may avail of the SCPWD
          discount on your order. Please note that the discount only applies to
          a maximum of a month’s worth of prescription medicines.
          <br />
          <br />
          To avail of the discount, please enter SCPWD as the coupon code and
          upload your SCPWD ID.
        </Collapsible>
        <Collapsible title="How do I avail of a discount or promotion?">
          Avail of the discount or promotion before you check out on the
          shopping cart page. Enter the coupon code inside the box below the
          cart subtotal and click “Checkout“.
        </Collapsible>
        <Collapsible title="Can I use multiple discounts or promotions in one order?">
          You may only use one discount or promotion per order. You will only be
          able to apply one coupon code per transaction. Promotions cannot be
          used in conjunction with Senior Citizen or PWD discounts.
        </Collapsible>
        <Collapsible title="What are MedGrocer's terms and conditions for promos?">
          Listed below are a few of our terms and conditions covered by
          MedGrocer promos. To know the full coverage, please read the complete
          terms and conditions{" "}
          <Link
            to="/terms-and-conditions"
            className="has-text-weight-bold has-text-primary"
          >
            here
          </Link>
          .
          <ul>
            <li>
              Promotional coupons, in the form of codes, may be issued by
              MedGrocer from time to time. These may include discounts and free
              products or services which may be used as payment for products
              purchased from MedGrocer.{" "}
            </li>
            <li>
              Promotional coupons are valid for the specified period stated on
              them, can only be redeemed a specified number of times, and cannot
              be used in conjunction with other promotional coupons (i.e.
              senior, PWD, or other ongoing MedGrocer promos or discount) unless
              otherwise stated.{" "}
            </li>
            <li>
              Products are subject to stock availability, and individual
              products may be excluded from certain promotions.{" "}
            </li>
            <li>
              Unless otherwise stated, the value of the promotional coupon only
              covers the price of the products in the order. Any additional fees
              and charges incurred in connection with the order, including
              convenience fee and additional taxes, shall be paid by the user.{" "}
            </li>
            <li>
              Promotional coupons are only applicable to first time orders and
              Metro Manila deliveries unless otherwise stated.{" "}
            </li>
            <li>
              Promotional coupons for ethical drugs are only applicable to
              medicines which require a doctor's prescription. This does not
              include vitamins, food supplements, and over-the-counter
              medicines. For promotions covering prescription medicines, please
              upload an updated prescription.{" "}
            </li>
            <li>
              If the credit from the promotional coupon is insufficient for the
              order, please pay for the difference.
            </li>
          </ul>
        </Collapsible>
        <h4>Returns</h4>
        <Collapsible title="What is your return policy?">
          All products sold on MedGrocer are subject to quality checks by our
          purchasing, storage, assembly, and delivery teams. Please inspect your
          order upon delivery. If your medicines are damaged upon delivery, you
          may refuse to accept the medicines.
          <br />
          <br />
          On a case-to-case basis, we can process requests to return or exchange
          the medicines within seven days upon delivery. Please make sure that
          you keep your delivery receipt and the medicines you want to return.
        </Collapsible>
        <Collapsible title="What medicines will you accept for returns?">
          We will replace damaged or wrongly dispensed medicines.
        </Collapsible>
        <Collapsible title="Can I return an item after seven days?">
          Any problems encountered seven days after the delivery may not be
          accommodated under our Return Policy. We highly encourage you to
          review and inspect your medicines upon delivery so that our courier
          could immediately facilitate the return, exchange, or refund request.
        </Collapsible>
        <Collapsible title="How long is the processing time for returns?">
          Returns are processed within 2-5 working days for NCR and within 7-10
          working days outside of NCR.
        </Collapsible>
        <h4>Privacy</h4>
        <Collapsible title="What information do you share?">
          Only coordinators processing your orders will have access to your
          medical information to ensure your medicines are properly dispensed.
          We may use anonymized data for analytics to improve our services or
          collaborate with partners.
        </Collapsible>
        <Collapsible title="How do I contact the Data Privacy Officer?">
          MedGrocer’s Data Privacy Officer provides support with any data
          privacy related questions, comments, concerns, or complaints. You may
          contact MedGrocer’s Data Privacy Officer through the following
          channels:
          <br />
          <br />
          MedGrocer 24F Centuria Medical Makati, Kalayaan cor. Salamanca St.,
          Makati City
          <br />
          Email address:{" "}
          <a
            href="mailto:dpo@medgrocer.com"
            className="has-text-weight-bold has-text-primary"
          >
            dpo@medgrocer.com
          </a>
        </Collapsible>
        <h4>Medicine</h4>
        <Collapsible title="What is the difference between branded and generic medicines?">
          Branded and generic medicines that are certified by the Food and Drug
          Administration are essentially the same. They have the same active
          ingredient in the medicine, but other ingredients that support or
          complement the active ingredient may differ.
        </Collapsible>
        <Collapsible title="Where are the generic medicines manufactured? Are they locally manufactured?">
          Our medicines are sourced from both international and local
          manufacturers. All medicines are FDA-approved. You can search for FDA
          certification for each medicine at fda.gov.ph.
        </Collapsible>

        <Collapsible title="What is Value Brand?">
          MedGrocer’s Value Brands are the most cost-effective FDA-approved
          medicines that are used by leading health insurers, hospitals, and
          clinics.
        </Collapsible>
        <Collapsible title="What are ethical drugs or prescription medicines?">
          Ethical drugs or prescription medicines are licensed medicines which
          require a doctor's prescription. This does not include vitamins, food
          supplements, and over-the-counter medicines.
        </Collapsible>
        <Collapsible title="How can I save money?">
          Try switching to our Value Brands. Seek the advice of your doctor and
          ask for alternative brands or alternative medicines that you can take.
        </Collapsible>
        <h4>Regulatory</h4>
        <Collapsible title="What is MedGrocer?">
          MedGrocer is an FDA-licensed online pharmacy. Simply order your
          medicines online and we’ll deliver them to you.
        </Collapsible>
        <Collapsible title="Are your medicines FDA-certified?">
          Yes. The Certificate of Product Registration of a particular medicine
          can be found at the website of the Food and Drug Administration.
        </Collapsible>
      </Container>
    </Layout>
  )
}
